var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "pb-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "pt-3"
  }, [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "user-activity-tracking",
      "show-labels": false,
      "load-dictionaries": _vm.loadDictionaries,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded
    },
    on: {
      "search": _vm.getData,
      "change": _vm.filterData,
      "async-search": _vm.onAsyncSearch,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('g-chart-custom', {
    ref: "chart1",
    attrs: {
      "title": "Grouped activities",
      "subtitle": "",
      "type": "ColumnChart",
      "loading": _vm.charts.chart1.loading,
      "data": _vm.charts.chart1.chartData,
      "options": _vm.charts.chart1.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-button-group', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart1.isDefaultMode,
            expression: "charts.chart1.isDefaultMode"
          }],
          staticClass: "mb-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.updateChart1('modules');
            }
          }
        }, [_vm._v(" Modules ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.updateChart1('users');
            }
          }
        }, [_vm._v(" Users ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "6"
    }
  }, [_c('g-chart-custom', {
    ref: "chart2",
    attrs: {
      "title": "Activities timeline",
      "subtitle": "",
      "type": "ColumnChart",
      "loading": _vm.charts.chart2.loading,
      "data": _vm.charts.chart2.chartData,
      "options": _vm.charts.chart2.chartOptions
    },
    scopedSlots: _vm._u([{
      key: "chartTopBar",
      fn: function fn() {
        return [_c('b-row', {
          attrs: {
            "align-h": "between"
          }
        }, [_c('b-col', [_c('b-button-group', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.charts.chart2.isDefaultMode,
            expression: "charts.chart2.isDefaultMode"
          }],
          staticClass: "mb-2"
        }, [_c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.updateChart2('days');
            }
          }
        }, [_vm._v(" Days ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.updateChart2('months');
            }
          }
        }, [_vm._v(" Months ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.updateChart2('years');
            }
          }
        }, [_vm._v(" Years ")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _vm.dataTable.visible ? _c('b-row', [_c('b-col', [_c('user-activity-tracking-table', {
    ref: "user-activity-tracking"
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }