<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="user-activity-tracking"
              :show-labels="false"
              :load-dictionaries="loadDictionaries"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @search="getData"
              @change="filterData"
              @async-search="onAsyncSearch"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="6">
            <g-chart-custom
              ref="chart1"
              title="Grouped activities"
              subtitle=""
              type="ColumnChart"
              :loading="charts.chart1.loading"
              :data="charts.chart1.chartData"
              :options="charts.chart1.chartOptions"
            >
              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col>
                    <b-button-group
                      v-show="charts.chart1.isDefaultMode"
                      class="mb-2"
                    >
                      <b-button
                        variant="outline-primary"
                        @click="updateChart1('modules')"
                      >
                        Modules
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="updateChart1('users')"
                      >
                        Users
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-row>
              </template>
            </g-chart-custom>
          </b-col>
          <b-col cols="12" lg="6">
            <g-chart-custom
              ref="chart2"
              title="Activities timeline"
              subtitle=""
              type="ColumnChart"
              :loading="charts.chart2.loading"
              :data="charts.chart2.chartData"
              :options="charts.chart2.chartOptions"
            >
              <template #chartTopBar>
                <b-row align-h="between">
                  <b-col>
                    <b-button-group
                      v-show="charts.chart2.isDefaultMode"
                      class="mb-2"
                    >
                      <b-button
                        variant="outline-primary"
                        @click="updateChart2('days')"
                      >
                        Days
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="updateChart2('months')"
                      >
                        Months
                      </b-button>
                      <b-button
                        variant="outline-primary"
                        @click="updateChart2('years')"
                      >
                        Years
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-row>
              </template>
            </g-chart-custom>
          </b-col>
        </b-row>

        <b-row v-if="dataTable.visible">
          <b-col>
            <user-activity-tracking-table ref="user-activity-tracking" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import FilteringPanel from "@/components/FilteringPanel";

import UserActivityTrackingTable from "@/views/UserManagement/UserActivityTrackingTable";

import GChartCustom from "@/components/GChartCustom";

export default {
  name: "UserActivityTracking",
  components: {
    FilteringPanel,
    UserActivityTrackingTable,
    GChartCustom
  },
  data: function() {
    return {
      filteringPanel: {
        loaded: false,
        selected: {},
        districts: [],
        filters: [
          {
            type: "select",
            title: "Module",
            tooltip: "Module name",
            name: "tracking_module",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            dataType: "string",
            multiple: true
          },
          /*
                    {
                        type: "select",
                        title: "Account",
                        tooltip: "Account name",
                        name: "tracking_account",
                        trackby: "id",
                        label: "label",
                        options: [],
                        selected: {},
                        dataType: "string",
                        multiple: true,
                        async: true,
                        loading: false,
                        startsWith: false
                    },
                    */
          {
            type: "select",
            title: "User name",
            tooltip: "User name",
            name: "tracking_user",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            dataType: "string",
            multiple: true
          },
          {
            type: "daterange",
            defaultRange: "Last 30 days",
            title: "Tracking period",
            name: "tracking_period",
            dataType: "datetime",
            tooltip: "Tracking period",
            allowEmpty: false
          }
        ]
      },
      dataTable: {
        visible: true
      },
      charts: {
        chart1: {
          isDefaultMode: true,
          rawData: [],
          emptyStates: [],
          loading: false,
          chartData: null,
          chartOptions: {
            height: 400,
            chartArea: {
              width: "80%",
              height: "60%"
            },
            vAxis: {
              viewWindow: {
                max: 200
              }
            },
            legend: {
              position: "bottom"
            },
            hAxis: {
              textStyle: {
                fontSize: 12
              }
            }
          }
        },
        chart2: {
          isDefaultMode: true,
          rawData: [],
          emptyStates: [],
          loading: false,
          chartData: null,
          chartOptions: {
            height: 400,
            chartArea: {
              width: "80%",
              height: "60%"
            },
            vAxis: {
              viewWindow: {
                max: 200
              }
            },
            legend: {
              position: "bottom"
            },
            hAxis: {
              textStyle: {
                fontSize: 12
              }
            }
          }
        }
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onAsyncSearch(payload) {
      if (payload.filterName === "tracking_account") {
        let accountsFilter = this.filteringPanel.filters.find(
          f => f.name === "tracking_account"
        );

        accountsFilter.loading = true;

        this.$api
          .post("dictionaries/accounts", {
            query: payload.query,
            starts_with: payload.startsWith
          })
          .then(response => {
            accountsFilter.loading = false;

            accountsFilter.options = response.map(u => ({
              id: u.id,
              label: u.name
            }));
          });
      }
    },
    async loadDictionaries() {
      let self = this;

      const users = async () => {
        let response = await this.$api.get("users");

        self.filteringPanel.filters.find(
          f => f.name === "tracking_user"
        ).options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      };

      const modules = async () => {
        let response = await this.$api.get("dictionaries/modules");

        self.filteringPanel.filters.find(
          f => f.name === "tracking_module"
        ).options = response.map(u => ({
          id: u.id,
          label: u.name
        }));
      };

      await Promise.all([modules(), users()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },

    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },

    filterData: function(e) {
      this.filteringPanel.selected = e;
    },
    getData() {
      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        // account: f.tracking_accounts ? f.tracking_accounts.map(i => i.label) : [],
        module: f.tracking_module ? f.tracking_module.map(i => i.label) : [],
        user_name: f.tracking_user ? f.tracking_user.map(i => i.label) : [],
        period: f.tracking_period
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.drawChart1(payload);

      this.drawChart2(payload);

      this.$refs["user-activity-tracking"].getData(payload);
    },
    async drawChart1(payload) {
      this.charts.chart1.loading = true;

      let response = await this.$api.post(
        "user/activity-tracking/chart1",
        payload
      );

      this.charts.chart1.loading = false;

      this.charts.chart1.rawData = response;

      if (response.length === 0) return;

      this.updateChart1("modules");
    },
    updateChart1(mode) {
      let headers = [];
      let data = [];

      if (mode === "modules") {
        this.charts.chart1.rawData.forEach(row => {
          let item = data.find(g => g.group_entity === row.module);

          if (!item) {
            let i = {
              group_entity: row.module,
              created: 0,
              viewed: 0,
              edited: 0
            };

            i.viewed = parseFloat(row.viewed);
            i.edited = parseFloat(row.edited);
            i.created = parseFloat(row.created);

            data.push(i);
          } else {
            item.viewed += parseFloat(row.viewed);
            item.edited += parseFloat(row.edited);
            item.created += parseFloat(row.created);
          }
        });

        headers = [["Module", "Created", "Viewed", "Edited"]];
      }

      if (mode === "users") {
        this.charts.chart1.rawData.forEach(row => {
          let item = data.find(g => g.group_entity === row.user_name);

          if (!item) {
            let i = {
              group_entity: row.user_name,
              created: 0,
              viewed: 0,
              edited: 0
            };

            i.viewed = parseFloat(row.viewed);
            i.edited = parseFloat(row.edited);
            i.created = parseFloat(row.created);

            data.push(i);
          } else {
            item.viewed += parseFloat(row.viewed);
            item.edited += parseFloat(row.edited);
            item.created += parseFloat(row.created);
          }
        });

        headers = [["User", "Created", "Viewed", "Edited"]];
      }
      data = [
        ...data.map(item => {
          return [
            item.group_entity,
            parseFloat(item.created || 0),
            parseFloat(item.viewed || 0),
            parseFloat(item.edited || 0)
          ];
        })
      ];

      //sort by created desc
      data = data.sort(function(a, b) {
        return b[1] - a[1];
      });

      this.charts.chart1.chartOptions.vAxis.viewWindow.max = Math.max(
        data.map(i => i[1])
      );
      this.charts.chart1.chartOptions.vAxis.viewWindow.min = 0;
      this.charts.chart1.chartData = headers.concat(data);
    },
    async drawChart2(payload) {
      this.charts.chart2.loading = true;

      let response = await this.$api.post(
        "user/activity-tracking/chart2",
        payload
      );

      this.charts.chart2.loading = false;

      if (response.length === 0) return;

      this.charts.chart2.rawData = response;

      this.updateChart2("days");
    },
    updateChart2(mode) {
      let headers = [];
      let data = [];
      let format = "";

      if (mode === "days") format = "YYYY-MM-DD";
      if (mode === "months") format = "YYYY, MMM";
      if (mode === "years") format = "YYYY";

      this.charts.chart2.rawData.forEach(row => {
        let period = moment(row.action_date).format(format);

        let item = data.find(g => g.group_entity === period);

        if (!item) {
          let i = {
            group_entity: period,
            created: 0,
            viewed: 0,
            edited: 0
          };

          i.viewed = parseFloat(row.viewed);
          i.edited = parseFloat(row.edited);
          i.created = parseFloat(row.created);

          data.push(i);
        } else {
          item.viewed += parseFloat(row.viewed);
          item.edited += parseFloat(row.edited);
          item.created += parseFloat(row.created);
        }
      });

      headers = [["Period", "Created", "Viewed", "Edited"]];

      data = [
        ...data.map(item => {
          return [
            item.group_entity,
            parseFloat(item.created || 0),
            parseFloat(item.viewed || 0),
            parseFloat(item.edited || 0)
          ];
        })
      ];

      /*
                        //sort by created desc
                        data = data.sort(function (a, b) {
                            return b[1] - a[1]
                        });
            */

      this.charts.chart2.chartOptions.vAxis.viewWindow.max = Math.max(
        data.map(i => i[1])
      );
      this.charts.chart2.chartOptions.vAxis.viewWindow.min = 0;
      this.charts.chart2.chartData = headers.concat(data);
    }
  },
  watch: {}
};
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}
</style>
